/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
					if($('.zoom').length){
						$('.zoom').zoom({
							url: '',
							icon: 'true'
						});
					}
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

				$('.owl-carousel').owlCarousel({
					items: 2,
					// nav: true,
					// autoplay: true
					responsiveClass:true,
			    responsive:{
			        0:{
			            items:1,
			            nav:false
			        },
			        992:{
			            items:2,
			            nav:false
			        }
			    }
				});
				/* ---- particles.js config ---- */

				particlesJS("particles-js", {
				  "particles": {
				    "number": {
				      "value": 200,
				      "density": {
				        "enable": true,
				        "value_area": 800
				      }
				    },
				    "color": {
				      "value": "#d0be60"
				    },
				    "shape": {
				      "type": "circle",
				      "stroke": {
				        "width": 0,
				        "color": "#d0be60"
				      },
				      "polygon": {
				        "nb_sides": 5
				      },
				      "image": {
				        "src": "img/github.svg",
				        "width": 100,
				        "height": 100
				      }
				    },
				    "opacity": {
				      "value": 0.5,
				      "random": false,
				      "anim": {
				        "enable": false,
				        "speed": 1,
				        "opacity_min": 0.1,
				        "sync": false
				      }
				    },
				    "size": {
				      "value": 3,
				      "random": true,
				      "anim": {
				        "enable": false,
				        "speed": 40,
				        "size_min": 0.1,
				        "sync": false
				      }
				    },
				    "line_linked": {
				      "enable": true,
				      "distance": 150,
				      "color": "#d0be60",
				      "opacity": 0.4,
				      "width": 1
				    },
				    "move": {
				      "enable": true,
				      "speed": 1,
				      "direction": "none",
				      "random": false,
				      "straight": false,
				      "out_mode": "out",
				      "bounce": false,
				      "attract": {
				        "enable": false,
				        "rotateX": 600,
				        "rotateY": 1200
				      }
				    }
				  },
				  "interactivity": {
				    "detect_on": "canvas",
				    "events": {
				      "onhover": {
				        "enable": true,
				        "mode": "grab"
				      },
				      "onclick": {
				        "enable": true,
				        "mode": "push"
				      },
				      "resize": true
				    },
				    "modes": {
				      "grab": {
				        "distance": 140,
				        "line_linked": {
				          "opacity": 1
				        }
				      },
				      "bubble": {
				        "distance": 400,
				        "size": 40,
				        "duration": 2,
				        "opacity": 8,
				        "speed": 3
				      },
				      "repulse": {
				        "distance": 200,
				        "duration": 0.4
				      },
				      "push": {
				        "particles_nb": 4
				      },
				      "remove": {
				        "particles_nb": 2
				      }
				    }
				  },
				  "retina_detect": true
				});



      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'press_room': {
      init: function() {
        // JavaScript to be fired on the about us page
				jQuery(function ($) {

					var $container = $('#news-stories'),
							filters = {},
							$filterDisplay = $('#filter-display');

					// Used to set filters with query string
					function getHashFilter() {
	          var hash = location.hash.match( /[^#]*$/i );
	          return decodeURIComponent( hash );
	        }

					if( getHashFilter() != "" ) {

						var hashFilter = getHashFilter(),
								$filterEl = $('#' + hashFilter);

						$container.isotope({
              itemSelector : '.news-item',
              layoutMode: 'fitRows',
              filter: '.' + hashFilter
            });

						$filterEl.attr('checked','checked');
						$filterEl.parent('li').siblings('li').children( 'input.all' ).removeAttr('checked');

					} else {

						$container.isotope({
							itemSelector : '.news-item',
							layoutMode : 'fitRows'
						});

					}

					$('#news-filters').on( 'change', function( jQEvent ) {
						var $checkbox = $( jQEvent.target );

						manageCheckbox( $checkbox );

						var comboFilter = getComboFilter( filters );

						$container.isotope({ filter: comboFilter });

						$filterDisplay.text( comboFilter );

					});

					function getComboFilter( filters ) {
					  var i = 0;
					  var comboFilters = [];
					  var message = [];

					  for ( var prop in filters ) {
					    message.push( filters[ prop ].join(' ') );
					    var filterGroup = filters[ prop ];
					    // skip to next filter group if it doesn't have any values
					    if ( !filterGroup.length ) {
					      continue;
					    }
					    if ( i === 0 ) {
					      // copy to new array
					      comboFilters = filterGroup.slice(0);
					    } else {
					      var filterSelectors = [];
					      // copy to fresh array
					      var groupCombo = comboFilters.slice(0); // [ A, B ]
					      // merge filter Groups
					      for (var k=0, len3 = filterGroup.length; k < len3; k++) {
					        for (var j=0, len2 = groupCombo.length; j < len2; j++) {
					          filterSelectors.push( groupCombo[j] + filterGroup[k] ); // [ 1, 2 ]
					        }

					      }
					      // apply filter selectors to combo filters for next group
					      comboFilters = filterSelectors;
					    }
					    i++;
					  }

					  var comboFilter = comboFilters.join(', ');
					  return comboFilter;
					}

					function manageCheckbox( $checkbox ) {
					  var checkbox = $checkbox[0];

					  var group = $checkbox.parents('.option-set').attr('data-group');

					  // create array for filter group, if not there yet
					  var filterGroup = filters[ group ];
					  if ( !filterGroup ) {
					    filterGroup = filters[ group ] = [];
					  }

						// set isAll to th
					  var isAll = $checkbox.hasClass('all');

					  // reset filter group if the all box was checked
					  if ( isAll ) {
					    delete filters[ group ];
					    if ( !checkbox.checked ) {
					      checkbox.checked = 'checked';
					    }
					  }
					  // index of
					  var index = $.inArray( checkbox.value, filterGroup );

					  if ( checkbox.checked ) {
					    var selector = isAll ? 'input' : 'input.all';
					    $checkbox.parent('li').siblings('li').children( selector ).removeAttr('checked');


					    if ( !isAll && index === -1 ) {
					      // add filter to group
					      filters[ group ].push( checkbox.value );
					    }

					  } else if ( !isAll ) {
					    // remove filter from group
					    filters[ group ].splice( index, 1 );
					    // if unchecked the last box, check the all
					    // if ( !$checkbox.parent('li').siblings('li').children('input[checked]').length ) {
							if( filterGroup.length === 0 ){
								console.log('none checked');
					      $checkbox.parent('li').siblings('li').children('input.all').attr('checked', 'checked');
					    }
					  }
					}

				});
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
